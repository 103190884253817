import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/material';
import './App.scss';
import Nav from './components/nav/nav';
import Footer from './components/footer/footer';

const { palette } = createTheme();
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffbb00',
    },
    secondary: {
      main: '#333333',
    },

    background: {
      default: '#ffffff',
    },
    blue: palette.augmentColor({
      color: {
        main: "#1F5C7A"
      }
    }),
    silver: palette.augmentColor({
      color: {
        main: "#F1F1F1"
      }
    }),
    gold: palette.augmentColor({
      color: {
        main: "#FABE2C"
      }
    }),
    black: palette.augmentColor({
      color: {
        main: "#333333"
      }
    })
  },
});


const ErrorPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Nav />
      <Container maxWidth="lg" className="content">
        <h1>Oops! Something went wrong.</h1>
        <p>We're sorry, but an error has occurred.</p>
        <p>Please try again later.</p>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default ErrorPage;