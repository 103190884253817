import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.scss';
import Nav from './components/nav/nav';
import Home from './components/home/home'
import Footer from './components/footer/footer';
import { useLocation, Outlet } from 'react-router-dom';

const { palette } = createTheme();
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffbb00',
    },
    secondary: {
      main: '#333333',
    },

    background: {
      default: '#ffffff',
    },
    blue: palette.augmentColor({
      color: {
        main: "#1F5C7A"
      }
    }),
    silver: palette.augmentColor({
      color: {
        main: "#F1F1F1"
      }
    }),
    gold: palette.augmentColor({
      color: {
        main: "#FABE2C"
      }
    }),
    black: palette.augmentColor({
      color: {
        main: "#333333"
      }
    })
  },
});

function App() {
  const location = useLocation()
  const { pathname } = location

  return (
    <ThemeProvider theme={theme}>
      <div className="MLUA">
        <Nav />
        <div className="content">
          {pathname === "/" ? <Home /> : <Outlet /> }
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
