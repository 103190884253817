import './footer.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function Footer() {
  return (
    <div className="footer">
      <p style={{ marginBottom: 2 }}>Find us on social media 
        <a href="https://www.facebook.com/MNLacrosseUmpireAssociation" target="_blank" rel="noreferrer"><FacebookIcon /></a>
        <a href="https://twitter.com/MLUA_Lacrosse" target="_blank" rel="noreferrer"><TwitterIcon /></a>
        <a href="https://www.youtube.com/channel/UChQY1pb2mz3GDdclnDJAAfg" target="_blank" rel="noreferrer"><YouTubeIcon /></a>
      </p>
      <div className="copy" style={{ marginTop: 2 }}>
        <p>Copyright {new Date().getFullYear()} MLUA</p>
        <p>Site by<a href="https://minnedev.com" target="_blank" rel="noreferrer">Minnedev</a></p>
        <p>Designed by Julie Cestaro</p>
      </div>
    </div>
  );
}