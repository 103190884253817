import { Container } from "@mui/material";
import BoardMember from "./boardMember";

import kip from "../../images/board/kipKilen.jpg"
import tim from "../../images/board/timLIkes.png"
import kristin from "../../images/board/kristinHaugen.jpg"
import benton from "../../images/board/bentonCampbell.jpg"
import steve from "../../images/board/steveKudebeh.jpg"

const members = [
  {
    name: "Kip Kilen",
    bio: "MLUA Board Chair. (Term ends 2024).", 
    email: "coachkk2@gmail.com",
    picture: kip,
  },
  {
    name: "Drew Devore",
    bio: "MLUA Vice-Chair (Term ends 2025)"
  },
  {
    name: "Tim Likes",
    bio: "MLUA Treasurer (Term ends 2024) and Past MLUA Vice-Chair (Term ends 2020). Also serves as the Coordinator for Referee development for U.S. Lacrosse in 7 states.",
    eamil: "chevylikscape@hotmail.com",
    picture: tim,
  },
  {
    name: "Kristin Haugen",
    bio: "MLUA Secretary (Term ends 2025).",
    email: "kc@kchaugenlaw.com",
    picture: kristin,
  },
  {
    name: "Benton Campbell",
    bio: "MLUA Member at Large (Term ends 2025).",
    email: "bentonc7@gmail.com",
    picture: benton,
  },
  {
    name: "Steve Kudebeh",
    bio: "MLUA Member at Large (Term ends 2024).",
    email: "kudebeh_steve@yahoo.com",
    picture: steve,
  },
]

export default function Board() {
  return (
    <Container maxWidth="lg">
      <h1>Meet the Board</h1>
      {members.map((member) => {return <BoardMember {...member} />})}
    </Container>

  );
}