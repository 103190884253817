import { Container } from "@mui/material";
import './uslLinks.scss';

export default function UslLinks() {
  return (
    <Container maxWidth="lg" className="usl">
      <h1>USL Links</h1>
      <h4>Following are helpful US Lacrosse links for general lacrosse information.  Check it out!</h4>
      <ul>
        <li><a href="http://www.uslacrosse.org/" target="_blank" rel="noreferrer">US Lacrosse</a> (USL) website home page - information surrounding men's, women's, youth & administrator lacrosse, membership renewal, & national events. </li>

        <li><a href="http://www.uslacrosse.org/about-the-sport/health-safety/equipment/approved-eyewear-list.aspx" target="_blank" rel="noreferrer"> Approved Eyewear</a> - list of eyewear approved by US Lacrosse</li>

        <li><a href="http://www.uslacrosse.org/about-the-sport/health-safety/equipment/legal-sticks.aspx" target="_blank" rel="noreferrer">Legal sticks for Women's Lacrosse</a> - list of approved sticks by US Lacrosse</li>

        <li><a href="http://www.uslacrosse.org/participants/officials/womens-officials-information/resources/official-hand-signals.aspx" target="_blank" rel="noreferrer">US Lacrosse Official Signals (animated)</a> - animated officials signals taken from the US Lacrosse Officials Online Training</li>

        <li><a href="http://www.uslacrosse.org/participants/officials/womens-officials-information/resources/official-hand-signals.aspx" target="_blank" rel="noreferrer">US Lacrosse Official Signals (animated)</a> - animated officials signals taken from the US Lacrosse Officials Online Training</li>
        <li><a href="http://www.uslacrosse.org/portals/1/documents/pdf/participants/officials/resources/womens/field-measurements.pdf" >Women's Field Diagram</a> - shows the dimensions of a women's lacrosse field & how to line it.</li>

        <li><a href="http://www.uslacrosse.org/participants/officials/womens-officials-information/resources/official-hand-signals.aspx" target="_blank" rel="noreferrer">US Lacrosse Official Signals (animated)</a> - animated officials signals taken from the US Lacrosse Officials Online Training</li>
        <li><a href="http://www.uslacrosse.org/portals/1/documents/pdf/participants/officials/resources/womens/how-to-line-the-field.pdf" >How to line a women's lacrosse field</a> - directions to line a women's lacrosse field.</li>
      </ul>
    </Container>
  );
}