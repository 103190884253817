import { Button, Grid, Container } from "@mui/material";
import './home.scss';

export default function Home() {
  return (
    <div className="home">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <h1>We Are The Minnesota Lacrosse Umpires Association</h1>
            <p>MLUA is a not-for-profit membership organization that provides trained and qualified officials for all levels of girls' and women's lacrosse in the upper midwest area of the United States. We service high school and youth lacrosse in the state of Minnesota as well as college lacrosse games across the upper midwest (including Minnesota, Wisconsin, North Dakota, South Dakota, and Iowa).  All of our umpires are members of US Lacrosse and are trained and rated in accordance with US Lacrosse guidelines for the Women's Division.</p>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button size="large" className="button" id="homeButton" color="blue" variant="contained">Become An Official</Button>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ bgcolor: '#FABE2C', padding: 4 }}>
        <Container maxWidth="md">
          <h1 className="white" style={{ textAlign: "center" }}>2023 Summer Ref Training</h1>
          <p>Join us for our 2023 summer referee training. We will be hosting a series of training sessions to help you become a better referee. We will cover everything from the basics to advanced techniques. We will also have a special guest speaker who will be sharing their experiences and tips for success. Don't miss out on this great opportunity to improve your skills and become a better referee.</p>
        </Container>
      </Container>
    </div>
  );
}