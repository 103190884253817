import { Container, Grid, Button } from "@mui/material";
import "./officialInfo.scss";

export default function OfficialInfo() {
  return (
    <div className="officialInfo">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <h1>Training Info</h1>
            <p><b>New officials</b> are expected to attend 3 hours of classroom training as well as 3 hours of field training to enforce the techniques learned in the classroom.  If you are interested in signing up for training as a new referee, please contact board member Steve Kudebeh at <a href="mailto:kudebeh_steve@yahoo.com">kudebeh_steve@yahoo.com</a></p>
            <p><b>Experienced High School officials</b> are expected to attend 3 hours of classroom training as well as 3 hours of field training to enforce the techniques learned in the classroom.</p>
            <p>Please see the following dates/times & venues for spring and summer officials training & training fees associated with training.</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button size="large" className="button" id="infoButton" color="blue" variant="contained">Become An Official</Button>
            <h2>Training Materials</h2>
            <ul>
              {/* <li><a href="" target="_blank" rel="noreferrer">2022 NFHS Rulebook</a></li>
              <li><a href="" target="_blank" rel="noreferrer">2023 USA Lacrosse Youth Rulebook</a></li> */}
              <li><a href="https://cdn1.sportngin.com/attachments/document/8afe-2889961/2023_MSlax_Rules_Summary_by_Age_Level.docx__1_.pdf?_gl=1%2Aj8ynf0%2A_ga%2AMTgwMjI5MjQyMC4xNjUxODQ3OTQz%2A_ga_PQ25JN9PJ8%2AMTY3NzUxNTE0Mi4zLjEuMTY3NzUxNTI1My4wLjAuMA..#_ga=2.210967639.333973122.1677515143-1802292420.1651847943" target="_blank" rel="noreferrer">2023 MSLax Rules Summary</a></li>
              <li><a href="https://www.nfhs.org/sports-resource-content/girls-lacrosse-rules-changes-2023/" target="_blank" rel="noreferrer">2023 NFHS Rule Changes</a></li>
              <li><a href="https://www.usalacrosse.com/sites/default/files/documents/Rules/2023-GirlsYouth-%20RuleChangeSummary.pdf" target="_blank" rel="noreferrer">2023 Youth Rule Changes</a></li>
              {/* <li><a href="" target="_blank" rel="noreferrer">2023 Youth Exam - take actual exam on USA Lacrosse website</a></li>
              <li><a href="" target="_blank" rel="noreferrer">2023 Officials Manual</a></li>
              <li><a href="" target="_blank" rel="noreferrer">2-Person Referee Mechanics</a></li>
              <li><a href="" target="_blank" rel="noreferrer">3-Person Referee Mechanics</a></li> */}
            </ul>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ bgcolor: '#FABE2C', padding: 4 }} style={{ marginTop: 25 }}>
        <Container maxWidth="md">
          <h1 className="white" style={{ textAlign: "center" }}>2023 Summer Ref Training</h1>
          <p>Join us for our 2023 summer referee training. We will be hosting a series of training sessions to help you become a better referee. We will cover everything from the basics to advanced techniques. We will also have a special guest speaker who will be sharing their experiences and tips for success. Don't miss out on this great opportunity to improve your skills and become a better referee.</p>
        </Container>
      </Container>
      <Container maxWidth="lg" style={{ marginTop: 25 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <h1>More Info</h1>
            <p><b>New officials</b> are expected to attend 3 hours of classroom training as well as 3 hours of field training to enforce the techniques learned in the classroom.  If you are interested in signing up for training as a new referee, please contact board member Steve Kudebeh at <a href="mailto:kudebeh_steve@yahoo.com">kudebeh_steve@yahoo.com</a></p>
            <p><b>Experienced High School officials</b> are expected to attend 3 hours of classroom training as well as 3 hours of field training to enforce the techniques learned in the classroom.</p>
            <p>Please see the following dates/times & venues for spring and summer officials training & training fees associated with training.</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img style={{ marginTop: 50 }} width={"100%"} src="https://images.squarespace-cdn.com/content/v1/5216497ce4b0734032a4c07a/1517593775033-T9SV40AOVB15ZR6BN56U/2016-May+%289%29.JPG?format=750w" alt="Kip Kilen" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}