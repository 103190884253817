import { Container, Grid } from '@mui/material';
import './memberInfo.scss';
import ref from '../../images/BlakeEPPlayerPic.jpg'

export default function MemberInfo() {
  return (
    <div className="memberInfo">
      <Container maxWidth="lg">
        <h1>Member Information</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <h2>MLUA Policies & Procedures</h2>
            <p>The MLUA Policies & Procedures contain guidelines on dues, official & travel fees, game delays, professionalism, game formats, required uniform, assigning games, umpire suspension/probation, ethical code of conduct, grievance policies, & rating requirements.</p>

            <h2>MLUA ANNUAL MEMBERSHIP DUES:</h2>
            <p>The information provided below is found on page 3 of the MLUA Policies & Procedures document.</p>
            <ul>
              <li>$105 if paid on or before February 1 of the current season (on time)</li>
              <li>$125 if paid on or after February 2 of the current season ($20 late fee)</li>
              <li>First year umpires will pay $75, no late fee</li>
              <li>Youth umpire dues are $30</li>
              <li>A "starter kit" for officials can be purchased for $20 (Kit includes: Yellow "Ref" short sleeve shirt, USL Women's rule book, cards, yellow flag & coin)</li>
              <li>Out-state officials (who live more than 50 miles from the Twin Cities) will pay dues of $45. Late fees will apply according to the date paid (see above)</li>
              <li>Dues should be paid by Venmo to Treasurer <a href="https://account.venmo.com/u/timothy-mlua" target="_blank" rel="noreferrer">Tim Likes</a>.</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <img src={ref} alt="referee" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ bgcolor: '#FABE2C', padding: 4 }}>
        <Container maxWidth="md">
          <h2 className="white" style={{ textAlign: "center" }}>Please ensure you are registered with other required organizations for your season:</h2>
          <ul>
            <li><a href="http://www.uslacrosse.org/" target="_blank" rel="noreferrer">US Lacrosse Membership</a> (all officials)</li>
            <li><a href="https://app.arbitersports.com/registration/official?org=1709&role=3" target="_blank" rel="noreferrer">MSHSL Membership</a> (for those officiating High School games)</li>
          </ul>
        </Container>
      </Container>
    </div>
  );
}