import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Board from './components/board/board';
import MemberInfo from './components/memberInfo/memberInfo';
import OfficialInfo from './components/officialInfo/officialInfo';
import Resources from './components/resources/resources';
import UslLinks from './components/uslLinks/uslLinks';
import ErrorPage from './errorPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/official-info",
        element: <OfficialInfo />,
      },
      {
        path: "/member-info",
        element: <MemberInfo />,
      },
      {
        path: "/board",
        element: <Board />,
      },
      {
        path: "/resources",
        element: <Resources />,
      },
      {
        path: "/usl-links",
        element: <UslLinks />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
