import { Container, Grid } from "@mui/material"
import mslax from "../../images/mslax.jpeg"
import mshsl from "../../images/mshsl.png"

export default function Resources() {
  return (
    <Container maxWidth="lg">
      <h1>Resources</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <a href="https://www.mslax.net/" target="_blank" rel="noreferrer"><h3>Minnesota Schoolgirls Lacrosse Association</h3></a>
          <p>MSLax's mission is to promote the growth of girls lacrosse in Minnesota.  They work to foster a positive environment to teach skills to players and coaches.  They are the governing body for girls lacrosse in Minnesota currently serving girls in 3rd to 8th grade.</p>
          <a href="https://www.mlua.org/s/MSLax-Coach-and-Referee-Expectations.pdf" target="_blank" rel="noreferrer">MSLax Coach and Referee Expectations</a>
        </Grid>
        <Grid item xs={12} md={3}>
          <img src={mslax} alt="mslax" style={{ width: "100%", marginTop: "15px" }} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} md={3}>
          <img src={mshsl} alt="mslax" style={{ width: "100%", marginTop: "15px"  }} />
        </Grid>
        <Grid item xs={12} md={9}>
          <a href="http://mshsl.arbitersports.com/front/103385/Site" target="_blank" rel="noreferrer"><h3>Minnesota State High School League</h3></a>
          <p>The MSHSL is the sanctioned organization which provides competitive, equitable and uniform opportunities for high school students to learn valuable lessons through participation in athletics and fine arts. The League also provides support for member schools with programs that address sportsmanship, chemical health, scholarship recognition.</p>

          <p>There are currently 78 girl's lacrosse teams sanctioned with MSHSL which include individual & coop schools playing girl's lacrosse in the state of Minnesota. Most teams are located in the twin cities area, but there are pockets of girl's teams in Rochester, Owatonna, Faribault, St. Cloud, and Duluth regional areas.
          </p>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} md={9}>
            <a href="http://nsla.teamopolis.com/" target="_blank" rel="noreferrer"><h3>Northcentral Schoolgirls Lacrosse Association (NSLA)</h3></a>
            <p>
              The NSLA is the club organization which provides new girl's high school teams in the Minnesota/Wisconsin/North Dakota area an opportunity to play lacrosse during the March to May time period.
            </p>
            <p>
              There are currently 9 girl's lacrosse teams sanctioned with NLA which include mostly out-state areas to play high school lacrosse. Teams are located in Brainerd, Duluth, Fargo Grand Rapids, Mankato, Rocori, St. Cloud, & St. Paul areas.
            </p>
          </Grid>
          <Grid item xs={12} md={3}>
            
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} md={9}>
            <h3>Observation Program</h3>
            <p>
            We have been fortunate enough to receive funding from US Lacrosse in to continue our Umpire Observation program.  Primary objectives of this program are to develop consistency throughout our umpires and offer constructive feedback in an informal settigs.
            </p>
            {/* <p>
              Here are links to the documents used to observe officials:
            </p>
            <ol>
              <li></li>
            </ol> */}
            <p>Look within Arbiter to locate the 'Observer' position on assigned games.</p>
            <p>Please note, this is a US Lacrosse-sponsored program.  Funds will be paid when we receive funds from US Lacrosse.  Also officials being observed will be paid their full referee fees (2-person mechanics).</p>
          </Grid>
          <Grid item xs={12} md={3}>
            
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}