import React, { useState } from 'react'
import './nav.scss'
import logo from '../../images/MLUA_Logo.png'
import { NavLink, useLocation  } from "react-router-dom"

export default function Nav() {
  const [navOpen, setNavOpen] = useState(false)
  const location = useLocation()
  let homePage = location.pathname === '/'
  return (
    <div id="nav" className={navOpen ? '' : 'closed'}>
      <div id="logo">
        <NavLink exact to="/"><img src={logo} alt="MLUAs logo" className="mobileLogo" style={{ height: 57 }}/></NavLink>
      </div>
      <div className={navOpen ? 'buttonContainer change' : "buttonContainer"} onClick={() => setNavOpen(!navOpen)}>
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
      <nav>
      
        <NavLink exact to="/" className="link" onClick={() => setNavOpen(!navOpen)}>
          {({ isActive }) => (
            <p className={isActive ? "navItem selected" : "navItem"} gutterBottom>home</p>
          )}
        </NavLink>
      
        <NavLink exact to="/official-info" className="link" onClick={() => setNavOpen(!navOpen)} gutterBottom>
        {({ isActive }) => (
          <p className={isActive ? "navItem selected" : "navItem"} gutterBottom>official info</p>
        )}
        </NavLink>
      
        <NavLink exact to="/member-info" className="link" onClick={() => setNavOpen(!navOpen)}>
          {({ isActive }) => (
            <p className={isActive ? "navItem selected" : "navItem"}>mlua member info</p>
          )}
        </NavLink>
      
        <NavLink exact to="/">
          <img src={logo} alt="MLUAs logo"  class={homePage ? "" : "transparent" }/>
        </NavLink>
      
      {/* <h1 id="logoText" class={homePage ? "" : "transparent" }>minnedev</h1> */}
      {/* <img src={subPagesLogo} alt="Minnedevs logo" class={homePage ? "" : "transparent"} /> */}
      
        <NavLink exact to="/board" className="link" onClick={() => setNavOpen(!navOpen)}>
        {({ isActive }) => (
          <p className={isActive ? "navItem selected" : "navItem"}>mlua board</p>
        )}
        </NavLink>
      
        <NavLink exact to="/resources" className="link" onClick={() => setNavOpen(!navOpen)}>
        {({ isActive }) => (
          <p className={isActive ? "navItem selected" : "navItem"}>additional resources</p>
        )}
        </NavLink>
      
        <NavLink exact to="/usl-links" className="link" onClick={() => setNavOpen(!navOpen)}>
        {({ isActive }) => (
          <p className={isActive ? "navItem selected" : "navItem"}>usl links</p>
        )}
        </NavLink>
      
        
      </nav>
    </div>
  )
}